import TRANSACTION from './TRANSACTION'
import DEFAULT_CURRENCY_PARAMETERS from './DEFAULT_CURRENCY_PARAMETERS'
import MIN_AMOUNT from './MIN_AMOUNT'
import MIN_AMOUNT_OFFER from './MIN_AMOUNT_OFFER'
import COINS_WITH_DYNAMIC_FEE from './COINS_WITH_DYNAMIC_FEE'
import EVM_CONTRACTS_ABI from './EVM_CONTRACTS_ABI'
import ADDRESSES from './ADDRESSES'

export default {
  ADDRESSES,
  TRANSACTION,
  DEFAULT_CURRENCY_PARAMETERS,
  MIN_AMOUNT,
  MIN_AMOUNT_OFFER,
  COINS_WITH_DYNAMIC_FEE,
  EVM_CONTRACTS_ABI,
}
