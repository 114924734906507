export default {
  eth: 0.0006,
  bnb: 0.0006,
  matic: 0.0006,
  arbeth: 0.0006,
  aureth: 0.0006,
  xdai: 0.0006,
  ftm: 0.0006,
  phpx: 0.0006,
  avax: 0.0006,
  movr: 0.0006,
  one: 0.0006,
  ame: 0.0006,
  phi_v1: 0.0006,
  phi: 0.0006,
  fkw: 0.0006,
  btc: 0.0002,
  jot: 1,
  usdt: 0,
  erc: 1,
}
