/*

# Visible makers whitelist

useful for dev or custom builds (private room)

todo: read it from WP settings

The list is empty? => any maker will be allowed
*/

export default [
  // add peer ids:
  // 'Q...',
]
